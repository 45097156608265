<template>
  <div id="contact" class="py-5">
    <mdb-container class="py-5">
      <h2 class="text-primary arabic-font mt-3 mb-0">
        {{ $t("contact.title") }}
      </h2>
      <h2
        class="font-weight-bold h2-responsive text-dark title mb-3 mt-0"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("contact.subtitle") }}
      </h2>
      <div
        id="map-container"
        class="rounded map-container mb-5"
        style="height: 400px"
      >
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          style="border: 0"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ_YC8XflfrxIRKF4lDwv-G1g&key=AIzaSyBnDSUoOyGmNjAt7sVc9dDZDDf33UVZRZA"
          allowfullscreen
        ></iframe>
      </div>

      <!-- Contact Fabs -->
      <mdb-row class="align-items-center justify-content-center mt-5">
        <mdb-col md="4">
          <mdb-btn
            tag="a"
            size="sm"
            floating
            color="primary"
            class="accent-1"
            v-animateOnScroll="'fadeInUp'"
          >
            <mdb-icon fas icon="map-marker-alt" />
          </mdb-btn>
          <h2
            class="h2-responsive text-secondary"
            v-animateOnScroll="'fadeInUp'"
          >
            <small>{{ $t("contact.btn3") }}</small>
          </h2>
          <p class="text-secondary" v-animateOnScroll="'fadeInUp'">
            Av. Sant Antoni, 20<br />AD400 - La Massana
          </p>
        </mdb-col>
        <mdb-col md="4">
          <mdb-btn
            tag="a"
            floating
            color="primary"
            size="sm"
            class="accent-1"
            v-animateOnScroll="'fadeInUp'"
          >
            <mdb-icon fas icon="phone" />
          </mdb-btn>
          <h2
            class="h2-responsive text-secondary"
            v-animateOnScroll="'fadeInUp'"
          >
            <small>{{ $t("contact.btn1") }}</small>
          </h2>
          <p class="text-secondary" v-animateOnScroll="'fadeInUp'">
            (+376) 838 123
          </p>
        </mdb-col>
        <mdb-col md="4">
          <mdb-btn
            tag="a"
            size="sm"
            floating
            color="primary"
            class="accent-1"
            v-animateOnScroll="'fadeInUp'"
          >
            <mdb-icon fas icon="envelope" />
          </mdb-btn>
          <h2
            class="h2-responsive text-secondary"
            v-animateOnScroll="'fadeInUp'"
          >
            <small>{{ $t("contact.btn2") }}</small>
          </h2>
          <p class="text-secondary" v-animateOnScroll="'fadeInUp'">
            totenpita@gmail.com
          </p>
        </mdb-col>
      </mdb-row>
      <!-- /Contact Fabs -->
    </mdb-container>
  </div>
</template>

<script>
import {
  animateOnScroll,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
} from "mdbvue";

export default {
  name: "Contact",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>