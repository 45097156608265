<template>
  <mdb-view class="view">
    <mdb-mask
      class="d-flex justify-content-center align-items-center"
      overlay="black-strong"
    >
      <mdb-container class="my-5">
        <h2 class="text-primary arabic-font mb-0">
          {{ $t("testimonials.title") }}
        </h2>
        <h2
          class="font-weight-bold h2-responsive title mt-0"
          v-animateOnScroll="'pulse'"
        >
          {{ $t("testimonials.subtitle") }}
        </h2>

        <!-- Testimonials -->
        <mdb-container>
          <mdb-row class="justify-content-center align-items-center">
            <mdb-col md="10">
              <!-- Global Rating -->
              <a
                target="_blank"
                href="https://www.tripadvisor.com/Restaurant_Review-g2385361-d4062094-Reviews-OH_Kebab-La_Massana_La_Massana_Parish.html"
                ><mdb-row class="justify-content-center align-items-center">
                  <img
                    class="animated pulse"
                    src="@/assets/oh-kebab-tripadvisor.png"
                    alt="Logo Oh'Kebab TripAdvisor"
                    height="40"
                  />
                </mdb-row>
                <mdb-row class="justify-content-center align-items-baseline">
                  <p class="font-weight-bold mr-2">4.0</p>
                  <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                  <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                  <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                  <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                  <mdb-icon far icon="circle" class="tripAdvisorGreen" />
                  <p class="ml-2"><small>77 reviews</small></p>
                </mdb-row>
              </a>
              <!-- /Global Rating -->

              <mdb-carousel
                testimonial
                :interval="8000"
                :items="3"
                class="mt-3"
              >
                <!-- Template 1 -->
                <template #1>
                  <mdb-testimonial v-animateOnScroll="'fadeIn'">
                    <mdb-row class="align-items-top justify-content-center">
                      <mdb-col>
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <p class="font-weight-bold mb-2">
                          {{ $t("testimonials.t1-title") }}
                        </p>
                        <p>
                          <mdb-icon
                            icon="quote-left"
                            class="tripAdvisorGreen pr-1"
                          ></mdb-icon
                          >{{ $t("testimonials.t1-text") }}
                          <mdb-icon
                            icon="quote-right"
                            class="tripAdvisorGreen pl-1"
                          ></mdb-icon>
                        </p>
                        <p>
                          {{ $t("testimonials.t1-user-name") }}<br />
                          <small class="grey-text">{{
                            $t("testimonials.t1-date")
                          }}</small>
                        </p>
                      </mdb-col>
                    </mdb-row>
                  </mdb-testimonial>
                </template>
                <!-- /Template 1 -->

                <!-- Template 2 -->
                <template #2>
                  <mdb-testimonial v-animateOnScroll="'fadeIn'">
                    <mdb-row class="align-items-top justify-content-center">
                      <mdb-col>
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <p class="font-weight-bold mb-2">
                          {{ $t("testimonials.t2-title") }}
                        </p>
                        <p>
                          <mdb-icon
                            icon="quote-left"
                            class="tripAdvisorGreen pr-1"
                          ></mdb-icon
                          >{{ $t("testimonials.t2-text") }}
                          <mdb-icon
                            icon="quote-right"
                            class="tripAdvisorGreen pl-1"
                          ></mdb-icon>
                        </p>
                        <p>
                          {{ $t("testimonials.t2-user-name") }}<br />
                          <small class="grey-text">{{
                            $t("testimonials.t2-date")
                          }}</small>
                        </p>
                      </mdb-col>
                    </mdb-row>
                  </mdb-testimonial>
                </template>
                <!-- /Template 2 -->

                <!-- Template 3 -->
                <template #3>
                  <mdb-testimonial v-animateOnScroll="'fadeIn'">
                    <mdb-row class="align-items-top justify-content-center">
                      <mdb-col>
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <mdb-icon fas icon="circle" class="tripAdvisorGreen" />
                        <p class="font-weight-bold mb-2">
                          {{ $t("testimonials.t3-title") }}
                        </p>
                        <p>
                          <mdb-icon
                            icon="quote-left"
                            class="tripAdvisorGreen pr-1"
                          ></mdb-icon
                          >{{ $t("testimonials.t3-text") }}
                          <mdb-icon
                            icon="quote-right"
                            class="tripAdvisorGreen pl-1"
                          ></mdb-icon>
                        </p>
                        <p>
                          {{ $t("testimonials.t3-user-name") }}<br />
                          <small class="grey-text">{{
                            $t("testimonials.t3-date")
                          }}</small>
                        </p>
                      </mdb-col>
                    </mdb-row>
                  </mdb-testimonial>
                </template>
                <!-- /Template 3 -->
              </mdb-carousel>
            </mdb-col>
          </mdb-row>
        </mdb-container>
        <!-- /Testimonials -->
      </mdb-container>
    </mdb-mask>
  </mdb-view>
</template>

<script>
import {
  animateOnScroll,
  mdbContainer,
  mdbView,
  mdbMask,
  mdbRow,
  mdbCol,
  mdbCarousel,
  mdbIcon,
  mdbTestimonial,
} from "mdbvue";

export default {
  name: "Testimonials",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbView,
    mdbMask,
    mdbRow,
    mdbCol,
    mdbCarousel,
    mdbIcon,
    mdbTestimonial,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

.view {
  background-image: url("~@/assets/oh-kebab-home.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 70vh;
}

.tripAdvisorGreen {
  color: #00a680 !important;
}

@media only screen and (max-width: 1200px) {
  .view {
    height: 70vh;
  }
}

@media only screen and (max-width: 991px) {
  .view {
    height: 80vh;
  }
}

@media only screen and (max-width: 767px) {
  .view {
    height: 80vh;
  }
}

@media only screen and (max-width: 576px) {
  .view {
    height: 80vh;
  }
}

@media only screen and (max-width: 480px) {
  .view {
    height: 100vh;
  }
}
</style>
