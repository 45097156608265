<template>
  <div id="aboutUs">
    <mdb-container class="py-5 my-5">
      <h2 class="text-primary arabic-font mt-3 mb-0">
        {{ $t("gallery.title") }}
      </h2>
      <h2
        class="font-weight-bold h2-responsive text-dark title mb-3 mt-0"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("gallery.subtitle") }}
      </h2>
      <mdb-container>
        <mdb-row class="mdb-lightbox">
          <mdb-col md="4" @click.native="showLightbox(0)">
            <figure>
              <img
                :src="img1"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(1)">
            <figure>
              <img
                :src="img2"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(2)">
            <figure>
              <img
                :src="img3"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(3)">
            <figure>
              <img
                :src="img4"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(4)">
            <figure>
              <img
                :src="img5"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(5)">
            <figure>
              <img
                :src="img6"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(6)">
            <figure>
              <img
                :src="img7"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(7)">
            <figure>
              <img
                :src="img8"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
          <mdb-col md="4" @click.native="showLightbox(8)">
            <figure>
              <img
                :src="img9"
                class="img-fluid"
                alt="Oh Kebab Food"
                v-animateOnScroll="'fadeIn'"
              />
            </figure>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <mdb-lightbox
        :visible="visible"
        :imgs="[img1, img2, img3, img4, img5, img6, img7, img8, img9]"
        :index="index"
        @hide="handleHide"
      >
      </mdb-lightbox>
    </mdb-container>
  </div>
</template>

<script>
import {
  animateOnScroll,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbLightbox,
} from "mdbvue";
export default {
  name: "GalleryPage",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbLightbox,
  },
  data() {
    return {
      img1: require("@/assets/oh-kebab-restaurant-1.jpg"),
      img2: require("@/assets/oh-kebab-restaurant-2.jpg"),
      img3: require("@/assets/oh-kebab-restaurant-3.jpg"),
      img4: require("@/assets/oh-kebab-restaurant-4.jpg"),
      img5: require("@/assets/oh-kebab-restaurant-5.jpg"),
      img6: require("@/assets/oh-kebab-restaurant-6.jpg"),
      img7: require("@/assets/oh-kebab-restaurant-7.jpg"),
      img8: require("@/assets/oh-kebab-restaurant-8.jpg"),
      img9: require("@/assets/oh-kebab-restaurant-9.jpg"),
      visible: false,
      index: 0,
    };
  },
  methods: {
    showLightbox(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
figure {
  cursor: pointer;
}
</style>