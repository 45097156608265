<template>
  <mdb-navbar
    aria-label="navbar"
    transparent
    class="z-depth-0"
    animation="3"
    container
    color="secondary"
    dark
    position="top"
    scrolling
  >
    <!-- Brand -->
    <mdb-navbar-brand waves>
      <img src="@/assets/oh-kebab-halal.png" alt="Oh'Kebab Halal" height="30" />
    </mdb-navbar-brand>
    <mdb-navbar-brand waves>
      <a @click="goToHome">
        <img
          class="logo-oh-kebab-small"
          src="@/assets/logo_white.png"
          alt="Oh'Kebab Logo White"
          height="32"
        />
      </a>
    </mdb-navbar-brand>
    <!-- /Brand -->

    <!-- Toggler -->
    <mdb-navbar-toggler>
      <mdb-navbar-nav right>
        <a href="#home" v-mdb-smooth>
          <mdb-nav-item waves-fixed class="smooth-scroll">
            <small>{{ $t("navbar.home") }}</small>
          </mdb-nav-item>
        </a>
        <a href="#aboutUs" v-mdb-smooth>
          <mdb-nav-item waves-fixed class="smooth-scroll">
            <small>{{ $t("navbar.about") }}</small>
          </mdb-nav-item>
        </a>
        <a href="#carta" v-mdb-smooth>
          <mdb-nav-item waves-fixed class="smooth-scroll">
            <small>{{ $t("navbar.menu") }}</small>
          </mdb-nav-item>
        </a>
        <a href="#contact" v-mdb-smooth>
          <mdb-nav-item waves-fixed class="smooth-scroll">
            <small>{{ $t("navbar.contact") }}</small>
          </mdb-nav-item>
        </a>

        <mdb-nav-item class="toShow" waves-fixed>
          <a
            v-for="locale in locales"
            :key="locale.code"
            @click="setLocale(locale.code)"
            class="m-2"
            ><small>{{ locale.code.toUpperCase() }}</small></a
          >
        </mdb-nav-item>

        <mdb-dropdown class="nav-item toHide" tag="li" hover>
          <mdb-dropdown-toggle
            tag="a"
            navLink
            color="transparent"
            slot="toggle"
            waves-fixed
            ><small>{{
              $i18n.locale.toUpperCase()
            }}</small></mdb-dropdown-toggle
          >
          <mdb-dropdown-menu left color="secondary">
            <mdb-dropdown-item
              v-for="locale in locales"
              :key="locale.code"
              @click="setLocale(locale.code)"
              ><small>{{ locale.code.toUpperCase() }}</small></mdb-dropdown-item
            >
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
    <!-- /Toggler -->
  </mdb-navbar>
</template>

<script>
import {
  mdbSmooth,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
} from "mdbvue";
import { getSupportedLocales } from "@/util/i18n/supported-locales";

export default {
  name: "NavBar",
  directives: {
    mdbSmooth,
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
  },
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    goToHome() {
      const locale = this.$route.params.locale;
      this.$router.push(`/${locale}/${"/".replace(/^\/|\/$/g, "")}`);
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push(`/${locale}/${"/".replace(/^\/|\/$/g, "")}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

a {
  color: $white !important;
  :hover {
    color: $primary !important;
  }
}

.dropdown .dropdown-menu .dropdown-item {
  color: $secondary !important;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  color: $white !important;
}

.toShow {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .toShow {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .navbar.navbar-dark {
    background-color: $secondary !important;
  }
  .toHide {
    display: none;
  }
  .toShow {
    display: inline;
  }
}

@media only screen and (max-width: 767px) {
  .navbar.navbar-dark {
    background-color: $secondary !important;
  }
  .toHide {
    display: none;
  }
  .toShow {
    display: inline;
  }
}

@media only screen and (max-width: 480px) {
  .navbar.navbar-dark {
    background-color: $secondary !important;
  }
  .toHide {
    display: none;
  }
  .toShow {
    display: inline;
  }
}
</style>
