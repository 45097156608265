<template>
  <div id="hours" class="py-5">
    <mdb-container class="py-5">
      <h2
        class="text-primary arabic-font mt-3 mb-0"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("hours.title") }}
      </h2>
      <h2
        class="font-weight-bold h2-responsive title mb-3 mt-0"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("hours.subtitle") }}
      </h2>
      <mdb-col md="6">
        <p class="text-white" v-animateOnScroll="'pulse'">
          {{ $t("hours.text") }}
        </p>
      </mdb-col>
    </mdb-container>
  </div>
</template>

<script>
import { animateOnScroll, mdbContainer, mdbCol } from "mdbvue";

export default {
  name: "Hours",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbCol,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>
