<template>
  <div id="aboutUs">
    <mdb-container class="py-5 my-5">
      <h2
        class="text-primary arabic-font mt-3 mb-0"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("about.title") }}
      </h2>
      <h2
        class="font-weight-bold h2-responsive text-dark title mb-3 mt-0"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("about.subtitle") }}
      </h2>

      <mdb-row class="my-3">
        <mdb-col md="6" class="my-3" v-animateOnScroll="'fadeIn'">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid animated fadeIn"
              src="@/assets/oh-kebab-durum.webp"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>

        <mdb-col md="6" class="my-3">
          <h3 class="h3-responsive text-dark mb-5" v-animateOnScroll="'pulse'">
            {{ $t("about.smalltitle") }}
          </h3>
          <p
            class="left-then-center-mobile text-dark"
            v-animateOnScroll="'fadeInUpBig'"
          >
            {{ $t("about.txt1") }}
          </p>
          <p
            class="left-then-center-mobile text-dark"
            v-animateOnScroll="'fadeInUpBig'"
          >
            {{ $t("about.txt2") }}
          </p>
          <p
            class="left-then-center-mobile text-dark"
            v-animateOnScroll="'fadeInUpBig'"
          >
            <mdb-icon fas icon="utensils" color="primary" class="mr-3" />{{
              $t("about.pt1")
            }}
          </p>
          <p
            class="left-then-center-mobile text-dark"
            v-animateOnScroll="'fadeInUpBig'"
          >
            <mdb-icon fas icon="hamburger" color="primary" class="mr-3" />{{
              $t("about.pt2")
            }}
          </p>
          <p
            class="left-then-center-mobile text-dark"
            v-animateOnScroll="'fadeInUpBig'"
          >
            <mdb-icon fas icon="th-list" color="primary" class="mr-3" />{{
              $t("about.pt3")
            }}
          </p>
          <p
            class="left-then-center-mobile text-dark"
            v-animateOnScroll="'fadeInUpBig'"
          >
            <mdb-icon fas icon="user-friends" color="primary" class="mr-3" />{{
              $t("about.pt4")
            }}
          </p>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </div>
</template>

<script>
import {
  animateOnScroll,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbMask,
  mdbView,
  mdbIcon,
} from "mdbvue";

export default {
  name: "AboutUs",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbMask,
    mdbView,
    mdbIcon,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>
