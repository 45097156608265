<template>
  <!-- Footer -->
  <mdb-footer id="footer" class="font-small">
    <mdb-container class="pt-3">
      <mdb-row>
        <mdb-col md="12">
          <div class="mb-5 flex-center">
            <a
              target="_blank"
              href="https://www.facebook.com/KEBAB-OH-691861290899145/"
              ><mdb-icon fab icon="facebook" size="2x" />
            </a>
            <a target="_blank" href="https://www.instagram.com/oh.kebab/"
              ><mdb-icon fab icon="instagram" size="2x" class="mx-4" />
            </a>
            <a
              target="_blank"
              href="https://www.tripadvisor.com/Restaurant_Review-g2385361-d4062094-Reviews-OH_Kebab-La_Massana_La_Massana_Parish.html"
              ><mdb-icon fab icon="tripadvisor" size="2x" />
            </a>
          </div>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <div class="footer-copyright text-center py-3">
      <!-- <button @click="toggleLegalNotice()">Avís Legal</button> -->
      <mdb-container fluid>
        &copy; {{ new Date().getFullYear() }} Copyright: OhKebab
      </mdb-container>
      <a href="https://devloop.dev" target="_blank">
        <small
          >Made with <mdb-icon fas icon="heart" color="primary" /> by
          Devloop</small
        ></a
      >
    </div>
  </mdb-footer>
  <!-- Footer -->
</template>

<script>
import { mdbFooter, mdbContainer, mdbRow, mdbCol, mdbIcon } from "mdbvue";
export default {
  name: "Footer",
  components: {
    mdbFooter,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
  },
  // props: {
  //   showLegalNotice: Boolean,
  // },
  // data() {
  //   return {
  //     show: this.showLegalNotice,
  //   };
  // },
  // methods: {
  //   toggleLegalNotice() {
  //     console.log("salut", this.show);
  //     this.show = !this.show;
  //     console.log("coucou", this.show);
  //     this.$emit("input", this.show);
  //   },
  // },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

a:hover {
  color: $primary !important;
}
</style>
