<template>
  <div id="service" class="py-5">
    <mdb-container class="py-5">
      <h2
        class="text-primary arabic-font mt-3 mb-0"
        v-animateOnScroll="'fadeInUpBig'"
      >
        {{ $t("service.title2") }}
      </h2>
      <h2
        class="font-weight-bold h2-responsive text-dark title mb-3 mt-0"
        v-animateOnScroll="'fadeInUpBig'"
      >
        {{ $t("service.subtitle2") }}
      </h2>
      <mdb-row class="align-items-center justify-content-around mt-3 mb-5">
        <mdb-col>
          <a target="_blank" href="https://cityxerpa.com/">
            <img
              class="logo-cityxerpa animated pulse"
              src="@/assets/oh-kebab-cityxerpa.svg"
              alt="Oh'Kebab CityXerpa"
              height="60"
              v-animateOnScroll="'fadeInUpBig'"
            />
          </a>
        </mdb-col>
        <mdb-col>
          <a target="_blank" href="https://glovoapp.com/">
            <img
              class="logo-cityxerpa animated pulse"
              src="@/assets/oh-kebab-glovo.svg"
              alt="Oh'Kebab Glovo"
              height="60"
              v-animateOnScroll="'fadeInUpBig'"
            />
          </a>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </div>
</template>

<script>
import { animateOnScroll, mdbRow, mdbCol, mdbContainer } from "mdbvue";

export default {
  name: "Service",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>
