<template>
  <div class="vue-app">
    <router-view />
  </div>
</template>

<script>
import {
  setDocumentLang,
  setDocumentTitle,
  setDocumentDescription,
} from "@/util/i18n/document";

export default {
  name: "App",
  mounted() {
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }

        setDocumentLang(newLocale);
        setDocumentTitle(this.$t("app.title"));
        setDocumentDescription(this.$t("app.description"));
      },
      { immediate: true }
    );
  },
};
</script>

<style lang="scss">
$image-path: "~@/../mdb/mdbvue/img";
@import "~@/../mdb/mdbvue/scss/mdb-pro.scss";

@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.vue-app {
  text-align: center;
}
</style>
