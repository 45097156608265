<template>
  <div class="home">
    <Header />
    <main>
      <AboutUs />
      <Menu />
      <Gallery />
      <Testimonials />
      <Service />
      <Hours />
      <Contact />
    </main>
    <!-- <LegalNotice v-show="showLegalNotice == true" /> -->
    <Footer />
    <BackToTop />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import AboutUs from "@/components/AboutUs.vue";
import Menu from "@/components/Menu.vue";
import Gallery from "@/components/Gallery.vue";
import Testimonials from "@/components/Testimonials.vue";
import Service from "@/components/Service.vue";
import Hours from "@/components/Hours.vue";
import Contact from "@/components/Contact.vue";
// import LegalNotice from "@/components/LegalNotice.vue";
import Footer from "@/components/Footer.vue";
import BackToTop from "@/components/BackToTop.vue";

export default {
  name: "home",
  title:
    "OH'Kebab Restaurant | Durum, Pita, Hamburguesa, Pizzes... a La Massana, Andorra",
  components: {
    Header,
    AboutUs,
    Menu,
    Gallery,
    Testimonials,
    Service,
    Hours,
    Contact,
    // LegalNotice,
    Footer,
    BackToTop,
  },
  // data() {
  //   return {
  //     showLegalNotice: false,
  //   };
  // },
};
</script>

<style scoped lang="scss">
@import "../assets/_custom.scss";
</style>
