<template>
  <div id="carta">
    <mdb-container class="py-5 my-5">
      <h2 class="text-primary arabic-font mt-3 mb-0">{{ $t("menu.title") }}</h2>
      <h2
        class="font-weight-bold h2-responsive title mt-0 mb-3"
        v-animateOnScroll="'pulse'"
      >
        {{ $t("menu.subtitle") }}
      </h2>

      <mdb-row class="justify-content-center align-items-center">
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Durum'"
          :active="show == 'Durum'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn1") }}</mdb-btn
        >
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Pita'"
          :active="show == 'Pita'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn2") }}</mdb-btn
        >
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Hamburguesa'"
          :active="show == 'Hamburguesa'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn3") }}</mdb-btn
        >
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Entrants'"
          :active="show == 'Entrants'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn4") }}</mdb-btn
        >
      </mdb-row>
      <mdb-row class="justify-content-center align-items-center">
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Plats'"
          :active="show == 'Plats'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn5") }}</mdb-btn
        >
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Tacos'"
          :active="show == 'Tacos'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn10") }}</mdb-btn
        >
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Amanides'"
          :active="show == 'Amanides'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn7") }}</mdb-btn
        >
        <mdb-btn
          v-animateOnScroll="'pulse'"
          @click.native="show = 'Ofertes'"
          :active="show == 'Ofertes'"
          size="sm"
          outline="primary"
          class="filter"
          dark-waves
          >{{ $t("menu.btn8") }}</mdb-btn
        >
      </mdb-row>

      <!-- Durums -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-0"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Durum'"
      >
        {{ $t("menu.btn1") }}
      </h3>

      <hr style="background-color: white" v-if="show === 'Durum'" />

      <mdb-row
        class="my-5 justify-content-center align-items-center"
        v-if="show === 'Durum'"
      >
        <mdb-col md="4">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-durum.jpg"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.durum1") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">6,90€</p>
          </mdb-row>
          <p class=" mx-3">
            <small>{{ $t("menu.durum1-txt") }}</small>
          </p>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.durum2") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum2-txt") }}</small>
          </p>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.durum3") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum3-txt") }}</small>
          </p>
          <!-- /Choix 3 -->

          <!-- Choix 4 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.durum4") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum4-txt") }}</small>
          </p>
          <!-- /Choix 4 -->
        </mdb-col>

        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 5 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.durum5") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum5-txt") }}</small>
          </p>
          <!-- /Choix 5 -->

          <!-- Choix 6 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.durum6") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum6-txt") }}</small>
          </p>
          <!-- /Choix 6 -->

          <!-- Choix 7 -->
          <mdb-row class="justify-content-center align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.durum7") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum7-txt") }}</small>
          </p>
          <!-- /Choix 7 -->

          <!-- Choix 8 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.durum8")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.durum8-txt") }}</small>
          </p>
          <!-- /Choix 8 -->
        </mdb-col>
      </mdb-row>
      <!-- /Durums -->

      <!-- Pitas -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-0"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Pita'"
      >
        {{ $t("menu.btn2") }}
      </h3>

      <hr style="background-color: white" v-if="show === 'Pita'" />

      <mdb-row
        class="my-5 justify-content-center align-items-center"
        v-if="show === 'Pita'"
      >
        <mdb-col md="4">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-pita.jpg"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.pita1") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">6,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita1-txt") }}</small>
          </p>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.pita2") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita2-txt") }}</small>
          </p>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.pita3") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita3-txt") }}</small>
          </p>
          <!-- /Choix 3 -->

          <!-- Choix 4 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.pita4") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita4-txt") }}</small>
          </p>
          <!-- /Choix 4 -->
        </mdb-col>

        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 5 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">{{ $t("menu.pita5") }}</h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita5-txt") }}</small>
          </p>
          <!-- /Choix 5 -->

          <!-- Choix 6 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.pita6") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita6-txt") }}</small>
          </p>
          <!-- /Choix 6 -->

          <!-- Choix 7 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.pita7") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita7-txt") }}</small>
          </p>
          <!-- /Choix 7 -->

          <!-- Choix 8 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.pita8")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.pita8-txt") }}</small>
          </p>
          <!-- /Choix 8 -->
        </mdb-col>
      </mdb-row>
      <!-- /Pitas -->

      <!-- Hamburguesas -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-0"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Hamburguesa'"
      >
        {{ $t("menu.btn3") }}
      </h3>
      <p
        class="mb-3"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Hamburguesa'"
      >
        {{ $t("menu.subBtn3") }}
      </p>

      <hr style="background-color: white" v-if="show === 'Hamburguesa'" />

      <mdb-row
        class="my-5 justify-content-center align-items-center"
        v-if="show === 'Hamburguesa'"
      >
        <mdb-col md="4">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-hamburguesa.jpg"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.hamburguesa1") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">5,95€</p>
          </mdb-row>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.hamburguesa2") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">5,95€</p>
          </mdb-row>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.hamburguesa3") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">5,95€</p>
          </mdb-row>
          <!-- /Choix 3 -->

          <!-- Choix 4 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.hamburguesa4") }}</h3>
              <p><small></small></p>
            </div>
            <p class="text-primary text-right font-weight-bold">5,95€</p>
          </mdb-row>
          <!-- /Choix 4 -->
        </mdb-col>
      </mdb-row>
      <!-- /Hamburguesas -->

      <!-- Entrants -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-3"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Entrants'"
      >
        {{ $t("menu.btn4") }}
      </h3>

      <hr style="background-color: white" v-if="show === 'Entrants'" />

      <mdb-row
        class="my-5 justify-content-center align-items-top"
        v-if="show === 'Entrants'"
      >
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.entrants1")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </div>
            <p class="text-primary text-right font-weight-bold">2,00€</p>
          </mdb-row>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.entrants2")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </div>
            <p class="text-primary text-right font-weight-bold">3,00€</p>
          </mdb-row>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants3") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">4,95€</p>
          </mdb-row>
          <!-- /Choix 3 -->

          <!-- Choix 4 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants4") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">6,95€</p>
          </mdb-row>
          <!-- /Choix 4 -->
        </mdb-col>

        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 5 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants5") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">5,50€</p>
          </mdb-row>
          <!-- /Choix 5 -->

          <!-- Choix 6 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.entrants6")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </div>
            <p class="text-primary text-right font-weight-bold">4,95€</p>
          </mdb-row>
          <!-- /Choix 6 -->

          <!-- Choix 7 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.entrants7")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </div>
            <p class="text-primary text-right font-weight-bold">3,95€</p>
          </mdb-row>
          <!-- /Choix 7 -->

          <!-- Choix 8 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants8") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">4,95€</p>
          </mdb-row>
          <!-- /Choix 8 -->
        </mdb-col>

        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 9 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants9") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">4,95€</p>
          </mdb-row>
          <!-- /Choix 9 -->

          <!-- Choix 10 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants10") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">4,95€</p>
          </mdb-row>
          <!-- /Choix 10 -->

          <!-- Choix 11 -->
          <mdb-row class="justify-content-between align-items-top mx-3 my-3">
            <div>
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.entrants11")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </div>
            <p class="text-primary text-right font-weight-bold">3,95€</p>
          </mdb-row>
          <!-- /Choix 11 -->

          <!-- Choix 12 -->
          <!-- <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.entrants12") }}</h3>
            </div>
            <p class="text-primary text-right font-weight-bold">5,95€</p>
          </mdb-row> -->
          <!-- /Choix 12 -->
        </mdb-col>
      </mdb-row>
      <!-- /Entrants -->

      <!-- Plats -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-0"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Plats'"
      >
        {{ $t("menu.btn5") }}
      </h3>
      <p class="mb-3" v-animateOnScroll="'fadeIn'" v-if="show === 'Plats'">
        {{ $t("menu.subBtn5") }}
      </p>

      <hr style="background-color: white" v-if="show === 'Plats'" />

      <mdb-row
        class="my-5 justify-content-center align-items-center"
        v-if="show === 'Plats'"
      >
        <mdb-col md="4">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-plats.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats1") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">12,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats1-txt") }}</small>
          </p>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats2") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">9,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats2-txt") }}</small>
          </p>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats3") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">9,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats3-txt") }}</small>
          </p>
          <!-- /Choix 3 -->

          <!-- Choix 4 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats4")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">9,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats4-txt") }}</small>
          </p>
          <!-- /Choix 4 -->
        </mdb-col>

        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 5 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats5") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">9,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats5-txt") }}</small>
          </p>
          <!-- /Choix 5 -->

          <!-- Choix 6 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats6") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">9,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats6-txt") }}</small>
          </p>
          <!-- /Choix 6 -->

          <!-- Choix 7 -->
          <!-- <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats7") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats7-txt") }}</small>
          </p> -->
          <!-- /Choix 7 -->

          <!-- Choix 8 -->
          <!-- <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats8") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats8-txt") }}</small>
          </p> -->
          <!-- /Choix 8 -->

          <!-- Choix 9 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.plats9") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">9,90€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.plats9-txt") }}</small>
          </p>
          <!-- /Choix 9 -->
        </mdb-col>
      </mdb-row>
      <!-- /Plats -->

      <!-- Tacos -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-3"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Tacos'"
      >
        {{ $t("menu.btn10") }}
      </h3>

      <hr style="background-color: white" v-if="show === 'Tacos'" />

      <mdb-row
        class="my-5 justify-content-center align-items-center"
        v-if="show === 'Tacos'"
      >
        <mdb-col md="4">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-tacos.jpg"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.tacos1") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,50€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.tacos1-txt") }}</small>
          </p>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.tacos2")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.tacos2-txt") }}</small>
          </p>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.tacos3") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">7,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.tacos3-txt") }}</small>
          </p>
          <!-- /Choix 3 -->
        </mdb-col>
      </mdb-row>
      <!-- /Tacos -->

      <!-- Amanides -->
      <h3
        class="font-weight-bold h3-responsive mt-5 mb-3"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Amanides'"
      >
        {{ $t("menu.btn7") }}
      </h3>

      <hr style="background-color: white" v-if="show === 'Amanides'" />

      <mdb-row
        class="my-5 justify-content-center align-items-center"
        v-if="show === 'Amanides'"
      >
        <mdb-col md="4">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-amanides.jpg"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="4" class="text-left mt-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.amanides1") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">6,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.amanides1-txt") }}</small>
          </p>
          <!-- /Choix 1 -->

          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.amanides2")
                }}<mdb-icon
                  fas
                  icon="leaf"
                  color="success"
                  class="mx-3"
                  size="sm"
                />
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">5,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.amanides2-txt") }}</small>
          </p>
          <!-- /Choix 2 -->

          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <mdb-col class="flex-grow-1 mx-0 px-0">
              <h3 class="h3-responsive mb-0">
                {{ $t("menu.amanides3") }}
              </h3>
            </mdb-col>
            <p class="text-primary text-right font-weight-bold">6,95€</p>
          </mdb-row>
          <p class="mx-3">
            <small>{{ $t("menu.amanides3-txt") }}</small>
          </p>
          <!-- /Choix 3 -->
        </mdb-col>
      </mdb-row>
      <!-- /Amanides -->

      <!-- Ofertes -->
      <h3
        class="font-weight-bold h3-responsive mt-5"
        v-animateOnScroll="'fadeIn'"
        v-if="show === 'Ofertes'"
      >
        {{ $t("menu.btn8") }}
      </h3>
      <p class="mb-3" v-animateOnScroll="'fadeIn'" v-if="show === 'Ofertes'">
        {{ $t("menu.subBtn8") }}
      </p>

      <hr style="background-color: white" v-if="show === 'Ofertes'" />

      <mdb-row
        class="my-5 justify-content-center align-items-top"
        v-if="show === 'Ofertes'"
      >
        <mdb-col md="3" class="my-3">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-oferta-1.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="3" class="text-left my-3">
          <!-- Choix 1 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.ofertes1") }}</h3>
              <p>
                <small
                  >{{ $t("menu.ofertes1-1") }}<br />{{ $t("menu.ofertes1-2")
                  }}<br />{{ $t("menu.ofertes1-3") }}<br />{{
                    $t("menu.ofertes1-4")
                  }}</small
                >
              </p>
            </div>
            <p class="text-primary text-right font-weight-bold">24,50€</p>
          </mdb-row>
          <!-- /Choix 1 -->
        </mdb-col>
        <mdb-col md="3" class="my-3">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-oferta-2.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="3" class="text-left my-3">
          <!-- Choix 2 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.ofertes2") }}</h3>
              <p>
                <small
                  >{{ $t("menu.ofertes2-1") }}<br />{{ $t("menu.ofertes2-2")
                  }}<br />{{ $t("menu.ofertes2-3") }}<br />{{
                    $t("menu.ofertes2-4")
                  }}</small
                >
              </p>
            </div>
            <p class="text-primary text-right font-weight-bold">31,90€</p>
          </mdb-row>
          <!-- /Choix 2 -->
        </mdb-col>

        <mdb-col md="3" class="my-3">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-oferta-3.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="3" class="text-left my-3">
          <!-- Choix 3 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.ofertes3") }}</h3>
              <p>
                <small
                  >{{ $t("menu.ofertes3-1") }}<br />{{ $t("menu.ofertes3-2")
                  }}<br />{{ $t("menu.ofertes3-3") }}<br />{{
                    $t("menu.ofertes3-4")
                  }}</small
                >
              </p>
            </div>
            <p class="text-primary text-right font-weight-bold">33,95€</p>
          </mdb-row>
          <!-- /Choix 3 -->
        </mdb-col>
        <mdb-col md="3" class="my-3">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-oferta-4.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="3" class="text-left my-3">
          <!-- Choix 4 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.ofertes4") }}</h3>
              <p>
                <small
                  >{{ $t("menu.ofertes4-1") }}<br />{{ $t("menu.ofertes4-2")
                  }}<br />{{ $t("menu.ofertes4-3") }}<br />{{
                    $t("menu.ofertes4-4")
                  }}</small
                >
              </p>
            </div>
            <p class="text-primary text-right font-weight-bold">24,50€</p>
          </mdb-row>
          <!-- /Choix 4 -->
        </mdb-col>

        <mdb-col md="3" class="my-3">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-oferta-5.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="3" class="text-left my-3">
          <!-- Choix 5 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.ofertes5") }}</h3>
              <p>
                <small
                  >{{ $t("menu.ofertes5-1") }}<br />{{ $t("menu.ofertes5-2")
                  }}<br />{{ $t("menu.ofertes5-3") }}<br />{{
                    $t("menu.ofertes5-4")
                  }}<br />{{ $t("menu.ofertes5-5") }}</small
                >
              </p>
            </div>
            <p class="text-primary text-right font-weight-bold">31,90€</p>
          </mdb-row>
          <!-- /Choix 5 -->
        </mdb-col>
        <mdb-col md="3" class="my-3">
          <mdb-view class="z-depth-3">
            <img
              class="img-fluid z-depth-2"
              src="@/assets/oh-kebab-oferta-6.png"
              alt="OH Kebab restaurant"
            />
            <mdb-mask flex-center waves />
          </mdb-view>
        </mdb-col>
        <mdb-col md="3" class="text-left my-3">
          <!-- Choix 6 -->
          <mdb-row class="justify-content-between align-items-top mx-3">
            <div>
              <h3 class="h3-responsive mb-0">{{ $t("menu.ofertes6") }}</h3>
              <p>
                <small
                  >{{ $t("menu.ofertes6-1") }}<br />{{ $t("menu.ofertes6-2")
                  }}<br />{{ $t("menu.ofertes6-3") }}<br />{{
                    $t("menu.ofertes6-4")
                  }}<br />{{ $t("menu.ofertes6-5") }}</small
                >
              </p>
            </div>
            <p class="text-primary text-right font-weight-bold">33,95€</p>
          </mdb-row>
          <!-- /Choix 6 -->
        </mdb-col>
      </mdb-row>
      <!-- Ofertes -->

      <hr style="background-color: white" />

      <!-- Menu Plats Vegetariens -->
      <mdb-row
        class="justify-content-center align-items-center mt-5"
        v-if="
          show == 'Durum' ||
            show == 'Pita' ||
            show == 'Hamburguesa' ||
            show == 'Tacos'
        "
      >
        <mdb-col md="4">
          <p class="border border-white rounded p-3">
            {{ $t("menu.footer1") }} +
            <span class="text-primary font-weight-bold">3,00€</span><br />
            {{ $t("menu.footer2") }}
          </p>
        </mdb-col>
      </mdb-row>
      <p>
        <small>{{ $t("menu.footer3") }}</small
        ><br />
        <mdb-icon
          fas
          icon="leaf"
          color="success"
          class="mx-2"
          size="sm"
        /><small>{{ $t("menu.footer4") }}</small> <br />
        <small
          ><i>{{ $t("menu.footer5") }}</i></small
        >
      </p>
      <!-- /Menu Plats Vegetariens -->
    </mdb-container>
  </div>
</template>

<script>
import {
  animateOnScroll,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbMask,
  mdbView,
  mdbIcon,
} from "mdbvue";

export default {
  name: "menu",
  directives: {
    animateOnScroll,
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbMask,
    mdbView,
    mdbIcon,
  },
  data() {
    return {
      show: "Durum",
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>
