<template>
  <div id="home">
    <header>
      <!--Navbar-->
      <NavBar />
      <!--/Navbar-->

      <mdb-view class="view">
        <mdb-mask
          class="d-flex justify-content-center align-items-center"
          overlay="black-light"
        >
          <mdb-container>
            <mdb-row class="justify-content-center align-items-center">
              <mdb-col col="12" class="text-md-left text-center">
                <mdb-row
                  class="justify-content-md-start justify-content-center align-items-top animated pulse"
                >
                  <img
                    class="logo-oh-kebab"
                    src="@/assets/logo_white.png"
                    alt="Logo Oh Kebab"
                    height="100"
                  />
                </mdb-row>
                <p class="animated pulse my-md-5 my-4">
                  {{ $t("header.text1") }}<br />
                  {{ $t("header.text2") }}
                </p>
                <div>
                  <a href="#carta" v-mdb-smooth
                    ><mdb-btn
                      outline="primary"
                      size="sm"
                      class="smooth-scroll animated fadeInLeft ml-md-0"
                      >{{ $t("header.btn2") }}</mdb-btn
                    ></a
                  >
                  <a href="#contact" v-mdb-smooth
                    ><mdb-btn
                      outline="white"
                      size="sm"
                      class="smooth-scroll animated fadeInUp"
                      >{{ $t("header.btn1") }}</mdb-btn
                    ></a
                  >
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-mask>
      </mdb-view>
    </header>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbView,
  mdbMask,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSmooth,
} from "mdbvue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Header",
  components: {
    NavBar,
    mdbContainer,
    mdbView,
    mdbMask,
    mdbRow,
    mdbCol,
    mdbBtn,
  },
  directives: {
    mdbSmooth,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";

.view {
  background-image: url("~@/assets/oh-kebab-home.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.fontLarge {
  font-size: 80px;
}

@media only screen and (max-width: 1200px) {
  .fontLarge {
    font-size: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .fontLarge {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .fontLarge {
    font-size: 60px;
  }
  .view {
    background-position: 85% center;
  }
}

@media only screen and (max-width: 480px) {
  .fontLarge {
    font-size: 60px;
  }
  .view {
    background-position: 85% center;
  }
}
</style>
